import { fmt } from 'IntlWrapper/IntlWrapper';
import dayjs from 'dayjs';
import isNil from 'lodash/isNil';

import {
  SIGN_AND_RETURN_PICKUP,
  ORDER_TYPES_VALUE
} from 'constants/shipment-details';
import {
  WHATSAPP_ACTION_LEVEL,
  WHATSAPP_ADDRESS_LOG,
  WHATSAPP_SCHEDULED_DATE_LOG,
  SECURITY_SEAL_VERIFIED
} from 'constants/hubs';
import {
  ACTION_TYPES,
  CANCELLATION_REQUEST,
  REJECTION_REASONS
} from 'constants/Deliveries';
import {
  SOHO,
  PUDO_STATES,
  PUDO_HUB_TYPE,
  PUDO_PROVIDER,
  DELIVERY_STATES_CODES,
  DELIVERY_CHILD_STATES,
  BAD_ADDRESS,
  REFUSAL_OPTIONS
} from 'constants/shipments';
import { formatRole } from 'utils/shipmentDetails';
import { isDefaultBostaCourier } from 'utils/helpers';
import { getVendorHubId } from 'utils/hubs';
import aclMatrix, { BUSINESS_ADMIN, VENDOR_MANAGER } from 'common/aclMatrix';
import {
  getAttemptsTypes,
  getMaxNumOfAttempts
} from 'common/countries/countries-mapping';

import { checkIfLogIsException, checkIfLogIsCancel } from './helpers';
import { courierStates } from './courier-states';
import { INVESTIGATION_EXCEPTIONS_CODES } from 'constants/order-logs';
import {
  INTERNATIONAL_ORDERS_RETURN_CHILD_STATE,
  INTERNATIONAL_ORDERS_RETURN_LABEL
} from 'constants/international-orders';

const states = {
  SEND: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    [PUDO_STATES.RECEIVED_AT_WAREHOUSE]: fmt({
      id: 'deliveries.states.recieved_at_warehouse_pudo'
    }),
    25: fmt({
      id: 'deliveries.states.fulfilled'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    [PUDO_STATES.READY_TO_PICK]: fmt({
      id: 'deliveries.states.out_for_delivery_pudo'
    }),
    45: fmt({
      id: 'deliveries.states.deliverd'
    }),
    [PUDO_STATES.DELIVERD]: fmt({
      id: 'deliveries.states.deliverd_pudo'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49: 'Canceled'
  },
  SIGN_AND_RETURN: {
    deliver: {
      10: fmt({
        id: 'deliveries.states.created'
      }),
      20: fmt({
        id: 'deliveries.states.route_assigned'
      }),
      21: fmt({
        id: 'deliveries.states.picked_up_from_business'
      }),
      24: fmt({
        id: 'deliveries.states.recieved_at_warehouse'
      }),
      30: fmt({
        id: 'deliveries.states.in_transit_between_hubs'
      }),
      22: fmt({
        id: 'deliveries.states.out_for_delivery'
      }),
      23: fmt({
        id: 'deliveries.states.picked_up_from_consignee'
      }),
      47: fmt({
        id: 'deliveries.states.exception'
      })
    },
    pickup: {
      42: fmt({
        id: 'deliveries.states.pickup_requested'
      }),
      43: fmt({
        id: 'deliveries.states.debriefed_successfully'
      }),
      47: fmt({
        id: 'deliveries.states.exception'
      }),
      20: fmt({
        id: 'deliveries.states.route_assigned'
      }),
      22: fmt({
        id: 'deliveries.states.out_for_pickup'
      }),
      23: fmt({
        id: 'deliveries.states.picked_up'
      }),
      24: fmt({
        id: 'deliveries.states.recieved_at_warehouse'
      }),
      30: fmt({
        id: 'deliveries.states.in_transit_between_hubs'
      }),
      41: fmt({
        id: 'deliveries.states.out_for_return'
      }),
      46: fmt({
        id: 'deliveries.states.returned_to_business'
      })
    },
    49: 'Canceled'
  },
  RTO: {
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_return'
    }),
    46: fmt({
      id: 'deliveries.states.returned_to_origin'
    }),
    [PUDO_STATES.CLOSED]: fmt({
      id: 'deliveries.states.cancelled_pudo'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    47.1: 'On hold',
    49: 'Canceled',
    60: fmt({
      id: 'deliveries.states.returned_to_stock'
    })
  },
  EXCHANGE_return: {
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_return'
    }),
    46: fmt({
      id: 'deliveries.states.exchanged_returned'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    47.1: 'On hold',
    49: 'Canceled',
    60: fmt({
      id: 'deliveries.states.returned_to_fulfillment_center'
    })
  },

  destructive: {
    48: (__, _, type) =>
      [ORDER_TYPES_VALUE.CRP, ORDER_TYPES_VALUE.CASH_COLLECTION].includes(type)
        ? fmt({
            id: 'deliveries.states.canceled'
          })
        : fmt({
            id: 'deliveries.states.deleted'
          }),
    50: () =>
      fmt({
        id: 'deliveries.states.canceled'
      }),
    100: () =>
      fmt({
        id: 'deliveries.states.lost'
      }),
    101: () =>
      fmt({
        id: 'deliveries.states.damaged'
      }),
    102: () =>
      fmt({
        id: 'deliveries.states.investigation'
      }),
    103: () =>
      fmt({
        id: 'deliveries.states.awaiting_business_action'
      }),
    105: () =>
      fmt({
        id: 'deliveries.states.on_hold'
      }),
    104: () =>
      fmt({
        id: 'deliveries.states.archived'
      })
  },
  CUSTOMER_RETURN_PICKUP: {
    10: fmt({
      id: 'deliveries.states.requested'
    }),
    20: fmt({
      id: 'deliveries.states.pickup_route_assigned'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_pickup'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_return'
    }),
    46: fmt({
      id: 'deliveries.states.returned'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49.1: fmt({
      id: 'deliveries.states.canceled'
    }),
    49: fmt({
      id: 'deliveries.states.deleted'
    }),
    60: fmt({
      id: 'deliveries.states.returned_to_fulfillment_center'
    })
  },
  EXCHANGE: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_return'
    }),
    45: fmt({
      id: 'deliveries.states.deliverd'
    }),
    46: fmt({
      id: 'deliveries.states.exchanged_returned'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49: 'Canceled',
    60: fmt({
      id: 'deliveries.states.returned_to_fulfillment_center'
    })
  },
  EXCHANGE_forward: {
    10: fmt({
      id: 'deliveries.states.created'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    21: fmt({
      id: 'deliveries.states.picked_up_from_business'
    }),
    22: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    23: fmt({
      id: 'deliveries.states.picked_up_from_consignee'
    }),
    24: fmt({
      id: 'deliveries.states.recieved_at_warehouse'
    }),
    30: fmt({
      id: 'deliveries.states.in_transit_between_hubs'
    }),
    41: fmt({
      id: 'deliveries.states.out_for_delivery'
    }),
    45: fmt({
      id: 'deliveries.states.deliverd'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49: 'Canceled'
  },
  CASH_COLLECTION: {
    11: fmt({
      id: 'deliveries.states.waiting_for_route'
    }),
    20: fmt({
      id: 'deliveries.states.route_assigned'
    }),
    40: fmt({
      id: 'deliveries.states.out_for_collection'
    }),
    45: fmt({
      id: 'deliveries.states.collected'
    }),
    47: fmt({
      id: 'deliveries.states.exception'
    }),
    49: fmt({
      id: 'deliveries.states.deleted'
    }),
    49.1: fmt({
      id: 'deliveries.states.canceled'
    })
  },
  Return: {
    49: 'On hold'
  }
};

const statesLogs = {
  SEND: {
    10: 'Order is created',
    10.1: 'Order is restarted to get back to state "Pickup requested"',
    10.2: 'Pickup is scheduled',
    20: 'Order is assigned to route',
    21: 'Order is picked up',
    24: 'Order is received at warehouse',
    25: 'Order is Fulfilled',
    24.1: 'Order is restarted to get back to state "Received at warehouse',
    24.2: 'Order is reset to get back to state "Received at warehouse',
    30: 'Order is being transferred between hubs',
    41: 'Order is out for delivery',
    45: 'Order is delivered',
    // 47: reason => `Order received an exception: "${reason}"`,
    47: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received an exception: "Postponed - the customer requested postponement for another day"',
      // 4: 'Order received an exception: "Cancelation - the customer wants to open the shipment"',
      4: 'Order received an exception. Exception reason: Cancel - the customer wants to open the shipment',
      4.1: 'Opening package is approved',
      5: 'Order received an exception: "Waiting for data modification - address or phone number not clear/wrong"',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      13.1: 'Order address got an update request',
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - phone number is wrong."',
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: 'Order info got an update request',
      16: 'Order received an exception: Failed Attempt',
      6: 'Order received an exception: "Cancelation - cancelaton of delivery at the request of the sender"',
      7: 'Order received an exception: "Customer is not answering"',
      8: 'Order received an exception: "Cancelation - the customer refuses to receive the shipment"',
      100: 'Order received an exception: "Bad Weather"',
      101: 'Order received an exception: "Suspicious consignee"',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    1: 'Order is canceled - Uncovered zone',
    1.1: 'Order is canceled',
    1.2: 'Order is canceled - Bad Address'
  },
  SIGN_AND_RETURN: {
    10: 'Order is created',
    10.1: 'Order is restarted to get back to state "Pickup requested"',
    10.2: 'Pickup is scheduled',
    20: 'Order is assigned to route',
    20.1: 'pickup request is assigned to route',
    21: 'Order is picked up',
    24: 'Order is received at warehouse',
    24.1: 'Order is restarted to get back to state "Received at warehouse',
    24.2: 'Order is reset to get back to state "Received at warehouse',
    30: 'Order is being transferred between hubs',
    22: 'Order is out for signature',
    22.1: 'pickup request is out for pickup',
    23: 'Order is  signed and picked up',
    23.1: 'pickup request is picked up',
    41: 'Order is out for return',
    46: 'Order is returned',
    42: (date) =>
      `Customer kept the document for review. The document should be picked up at ${new DeliveryStateFormatter()._formatDate(
        date,
        true
      )}`,
    42.1: (date) =>
      `Pickup request is created. Scheduled for ${new DeliveryStateFormatter()._formatDate(
        date,
        true
      )}`,
    43: 'Order is debriefed successfully',
    47: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received an exception: "Postponed - the customer requested postponement for another day"',
      4: 'Order received an exception. Exception reason: Cancel - the customer wants to open the shipment',
      18: 'Order received an exception: "الوصف / عدد العناصر لا يتطابق مع الطلب"',
      4.1: 'Opening package is approved',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      13.1: (oldAddress, newAddress) =>
        `A request to update the order address from [${oldAddress}]to [${newAddress}] is added.`,
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - wrong phone number."',
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: (phone) =>
        `A request to update the customer phone is added. New phone: [${phone}]`,
      6: 'Order received an exception: "Cancelation - cancelaton of signature at the request of the sender"',
      7: 'Order received an exception: "Customer is not answering"',
      8: 'Order received an exception: "Cancelation - the customer refuses to sign the document"',
      9: 'Order received an exception: "Cancelation - the customer refuses to hand the document to Bosta Courier."',
      16: 'Order received an exception: Failed Attempt',
      100: 'Order received an exception: "Bad Weather"',
      101: 'Order received an exception: "Suspicious consignee"',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    47.1: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received a exception: "Postponed - the customer requested postponement for another day"',
      5: 'Order received an exception: "Waiting for data modification - address or phone number not clear/wrong"',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      18: 'Order received an exception: "الوصف / عدد العناصر لا يتطابق مع الطلب"',
      13.1: (oldAddress, newAddress) =>
        `A request to update the order address from [${oldAddress}]to [${newAddress}] is added.`,
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: (phone) =>
        `A request to update the customer phone is added. New phone: [${phone}]`,
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - phone number is wrong."',
      16: 'Order received an exception: Failed Attempt',
      6: 'Order received a exception: "Cancelaton of delivery at the request of the sender"',
      7: 'Order received an exception: "Customer is not answering"',
      9: 'Order received an exception: "Cancelation - the customer refuses to hand the document to Bosta Courier."',
      20: 'Order received a return exception: "Retry return - the customer changed the address"',
      21: 'Order received a return exception: "Postponed - the customer requested postponement for another day"',
      22: 'Order received a return exception: "Waiting for data modification: address or phone number not clear/wrong"',
      23: 'Order received a return exception: "Customer is not answering"',
      24: 'Order received a return exception: "Business refused to receive the document"',
      25: 'Order received a return exception: "Retry return - Business is not in the address"',
      26: 'Order received a return exception: "Business refused to receive the shipment. The order is damaged."',
      27: 'Order received a return exception: "Business refused to receive the shipment. Empty order."',
      28: 'Order received a return exception: "Business refused to receive the shipment. The order is incomplete."',
      29: 'Order received a return exception: "Business refused to receive the shipment. The order does not belong the business."',
      30: 'Order received a return exception: "Business refused to receive the shipment. The order was opened while it should not."',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    1: 'Order is canceled - Uncovered zone',
    1.1: 'Order is canceled',
    1.2: 'Order is canceled - Bad Address'
  },
  RTO: {
    change_to_rto: (hub) => `${hub ? `[${hub}]` : ''} Order is changed to RTO`,
    change_to_rto_3_attempts: `Order is changed to RTO after ${
      getMaxNumOfAttempts().DELIVERY
    } failed delivery attempts`,
    redispatch_order:
      'Order is re-dispatched to get back to state "Received at warehouse"',
    20: 'Order is assigned to route',
    20.1: 'Return Package was requested',
    10: 'Order is created',
    24: 'Order received at warehouse',
    24.1: 'Order is restarted to get back to state "Received at warehouse',
    24.2: 'Order is reset to get back to state "Received at warehouse',
    24.3: 'Order return is rejected: Business refused to receive the shipment.',
    30: 'Order is being transferred  between hubs',
    41: 'Order is out for return',
    46: 'Order is returned',
    60: 'Order is Returned to Stock',
    // 47: reason => `Order received a return exception: "${reason}"`,
    47: {
      20: 'Order received a return exception: "Retry return - the customer changed the address"',
      21: 'Order received a return exception: "Postponed - the customer requested postponement for another day"',
      22: 'Order received a return exception: "Waiting for data modification: address or phone number not clear/wrong"',
      23: 'Order received a return exception: "Customer is not answering"',
      24: 'Order received a return exception: "Business refused to receive the shipment"',
      25: 'Order received a return exception: "Retry return - Business is not in the address"',
      26: 'Order received a return exception: "Business refused to receive the shipment. The order is damaged."',
      27: 'Order received a return exception: "Business refused to receive the shipment. Empty order."',
      28: 'Order received a return exception: "Business refused to receive the shipment. The order is incomplete."',
      29: 'Order received a return exception: "Business refused to receive the shipment. The order does not belong the business."',
      30: 'Order received a return exception: "Business refused to receive the shipment. The order was opened while it should not."',
      4.2: 'Return Package was requested',
      16: 'Order received an exception: Failed Attempt',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    47.1: 'On hold',
    1: 'Order moved to "On-hold" due to Uncovered zone',
    1.1: 'Order is canceled',
    1.2: 'Order is canceled - Bad Address'
  },
  EXCHANGE: {
    10: 'Order is created',
    10.1: 'Order is restarted to get back to state "Created"',
    10.2: 'Pickup is scheduled',
    20: 'Order is assigned to route',
    22: 'Order is out for exchange',
    21: 'Order is picked up',
    23: 'Order is exchanged',
    24: 'Order is received at warehouse',
    24.1: 'Order is restarted to get back to state "Received at warehouse"',
    24.2: 'Order is reset to get back to state "Created"',
    30: 'Order is being transferred between hubs',
    41: 'Order is out for return',
    // 41.1: 'Order is out for return',
    46: 'Order is returned',
    45: 'Order is delivered',
    // 47: reason => `Order received an exception: "${reason}"`,
    47: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received an exception: "Postponed - the customer requested postponement for another day"',
      // 4: 'Order received an exception: "Cancelation - the customer wants to open the shipment"',
      4: 'Order received an exception. Exception reason: Cancel - the customer wants to open the shipment',
      4.1: 'Opening package is approved',
      5: 'Order received an exception: "Waiting for data modification - address or phone number not clear/wrong"',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      13.1: 'Order address got an update request ',
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: 'Order info got an update request',
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - phone number is wrong."',
      18: 'Order received an exception: "الوصف / عدد العناصر لا يتطابق مع الطلب"',
      6: 'Order received an exception: "Cancelation of delivery at the request of the sender"',
      7: 'Order received an exception: "Customer is not answering"',
      8: 'Order received an exception: "Cancelation - the customer refuses to receive the shipment"',
      16: 'Order received an exception: Failed Attempt',
      20: 'Order received a return exception: "Retry return - the customer changed the address"',
      21: 'Order received a return exception: "Postponed - the customer requested postponement for another day"',
      22: 'Order received a return exception: "Waiting for data modification: address or phone number not clear/wrong"',
      23: 'Order received a return exception: "Customer is not answering"',
      24: 'Order received a return exception: "Business refused to receive the shipment"',
      25: 'Order received a return exception: "Retry return - Business is not in the address"',
      26: 'Order received a return exception: "Business refused to receive the shipment. The order is damaged."',
      27: 'Order received a return exception: "Business refused to receive the shipment. Empty order."',
      28: 'Order received a return exception: "Business refused to receive the shipment. The order is incomplete."',
      29: 'Order received a return exception: "Business refused to receive the shipment. The order does not belong the business."',
      30: 'Order received a return exception: "Business refused to receive the shipment. The order was opened while it should not."',
      100: 'Order received an exception: "Bad Weather"',
      101: 'Order received an exception: "Suspicious consignee"',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    1: 'Order is canceled - Uncovered zone',
    1.1: 'Order is canceled',
    60: fmt({
      id: 'deliveries.states.returned_to_fulfillment_center'
    }),
    1.2: 'Order is canceled - Bad Address'
  },

  destructive: {
    48: (
      _,
      attemptsCount,
      outboundAction,
      holder,
      deliveryType,
      delivery,
      actionsList
    ) =>
      attemptsCount >= getMaxNumOfAttempts().PICKUP
        ? `Order has been ${
            [ORDER_TYPES_VALUE.CRP, ORDER_TYPES_VALUE.CASH_COLLECTION].includes(
              deliveryType
            )
              ? 'canceled'
              : 'deleted'
          } due to ${getMaxNumOfAttempts().PICKUP} failed pickup attempts`
        : `Order has been ${
            [ORDER_TYPES_VALUE.CRP, ORDER_TYPES_VALUE.CASH_COLLECTION].includes(
              deliveryType
            )
              ? 'canceled'
              : 'deleted'
          } automatically ${
            actionsList?.state_code?.before === 24
              ? `as it was received by mistake`
              : ''
          }`,
    50: (_, attemptsCount, outboundAction, holder, deliveryType) =>
      attemptsCount >= getMaxNumOfAttempts().PICKUP
        ? `Order has been canceled due to ${
            getMaxNumOfAttempts().PICKUP
          } failed pickup attempts`
        : 'Order has been canceled automatically',

    100: (role, _) =>
      `Order is marked as Lost. The liability is on the ${role}`,
    101: (role, _) =>
      `Order is marked as Damaged and the liability is on the ${role}`,
    103: () =>
      'Order moved to on hold due to the inability to return the order to business',
    104: () =>
      `Order is turned into can't claim by the system due to 2 weeks passing over ${
        getMaxNumOfAttempts().RETURN
      } failed returned attempts`,
    102: (_, attempts, outboundAction, holder, deliveryType, delivery) =>
      delivery?.state?.lastExceptionCode
        ? delivery?.state?.lastExceptionCode === 26
          ? 'Order is on investigation: "Business refused to receive the shipment. The order is damaged."'
          : delivery?.state?.lastExceptionCode === 27
          ? 'Order is on investigation: "Business refused to receive the shipment. Empty order."'
          : delivery?.state?.lastExceptionCode === 28
          ? 'Order is on investigation: "Business refused to receive the shipment. The order is incomplete."'
          : delivery?.state?.lastExceptionCode === 29
          ? 'Order is on investigation: "Business refused to receive the shipment. The order does not belong the business."'
          : delivery?.state?.lastExceptionCode === 30
          ? 'Order is on investigation: "Business refused to receive the shipment. The order was opened while it should not."'
          : 'Order moved to state "Investigation" as is not received at warehouse during the debriefng process'
        : 'Order moved to state "Investigation" as is not received at warehouse during the debriefng process',
    105: () => 'The business opened a ticket to return the order'
  },

  CUSTOMER_RETURN_PICKUP: {
    10: 'Order is created',
    10.1: 'Order is restarted to get back to state Pickup Requested"',
    10.2: 'Order is restarted to get back to state Requested',
    10.3: 'Order is reset to get back to state Pickup Requested',
    20: 'Order is assigned to route',
    23: 'Order is picked up',
    22: 'Order is out for pickup',
    24: 'Order received at warehouse',
    24.1: 'Order is restarted to get back to state "Received at warehouse"',
    30: 'Order is being transferred between hubs',
    41: 'Order is out for return',
    46: 'Order is returned ',
    // 47: reason => `Order received an exception: "${reason}"`,
    47: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received a exception: "Postponed - the customer requested postponement for another day"',
      5: 'Order received an exception: "Waiting for data modification - address or phone number not clear/wrong"',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      13.1: 'Order address got an update request ',
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: 'Order info got an update request',
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - phone number is wrong."',
      6: 'Order received a exception: "Cancelation of delivery at the request of the sender"',
      18: 'Order received an exception: "الوصف / عدد العناصر لا يتطابق مع الطلب"',
      7: 'Order received an exception: "Customer is not answering"',
      9: 'Order received an exception: "Cancelation - the customer refuses to receive the shipment"',
      16: 'Order received an exception: Failed Attempt',
      20: 'Order received a return exception: "Retry return - the customer changed the address"',
      21: 'Order received a return exception: "Postponed - the customer requested postponement for another day"',
      22: 'Order received a return exception: "Waiting for data modification: address or phone number not clear/wrong"',
      23: 'Order received a return exception: "Customer is not answering"',
      24: 'Order received a return exception: "Business refused to receive the shipment"',
      25: 'Order received a return exception: "Retry return - Business is not in the address"',
      26: 'Order received a return exception: "Business refused to receive the shipment. The order is damaged."',
      27: 'Order received a return exception: "Business refused to receive the shipment. Empty order."',
      28: 'Order received a return exception: "Business refused to receive the shipment. The order is incomplete."',
      29: 'Order received a return exception: "Business refused to receive the shipment. The order does not belong the business."',
      30: 'Order received a return exception: "Business refused to receive the shipment. The order was opened while it should not."',
      100: 'Order received an exception: "Bad Weather"',
      101: 'Order received an exception: "Suspicious consignee"',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    1: 'Order moved to "On-hold" due to Uncovered zone',
    1.1: 'Order is canceled',
    1.2: 'Order is canceled - Bad Address',
    60: fmt({
      id: 'deliveries.states.returned_to_fulfillment_center'
    })
  },
  CASH_COLLECTION: {
    11: 'Order is created',
    11.1: 'Order is restarted to get back to state "Waiting for route"',
    20: 'Order is assigned to route',
    40: 'Order is out for collection',
    45: 'Order is collected',
    // 47: reason => `Order received an exception: "${reason}"`,
    47: {
      1: 'Order received an exception: "Retry delivery - the customer is not in the address"',
      2: 'Order received an exception: "Retry delivery - the customer changed the address"',
      3: 'Order received an exception: "Postponed - the customer requested postponement for another day"',
      5: 'Order received an exception: "Waiting for data modification - address or phone number not clear/wrong"',
      13: 'Order received an exception Exception reason: Waiting for data modification - address is not clear.',
      13.1: 'Order address got an update request ',
      14: 'Order received an exception. Exception reason: Waiting for data modification - wrong phone number.',
      14.1: 'Order info got an update request',
      13.2: 'Order received an exception: "Waiting for data modification - address is not clear"',
      14.2: 'Order received an exception: "Waiting for data modification - phone number is wrong."',
      16: 'Order received an exception: Failed Attempt',
      6: 'Order received an exception: "Cancelaton of delivery at the request of the sender"',
      7: 'Order received an exception: "Customer is not answering"',
      10: 'Order received an exception: "Cancelation - the customer refuses to give the cash"',
      100: 'Order received an exception: "Bad Weather"',
      101: 'Order received an exception: "Suspicious consignee"',
      default: (reason) => `Order received an exception: "${reason}"`
    },
    47.1: 'On hold',
    1: 'Order is canceled - Uncovered zone',
    1.1: 'Order is canceled',
    1.2: 'Order is canceled - Bad Address'
  },
  OUTBOUND_ACTIONS: {
    fake_attempt: 'Attempt marked as fake update',
    11: 'Exception reason is updated to  "المندوب لم يتواصل مع العميل"',
    12: 'Exception reason is updated to "منطقة التوصيل خارج نطاق تغطية بوسطة"',
    'Redispatch tomorrow': (action) => `Order is rescheduled to tomorrow`,
    'Return to shipper': (action) => `Order is canceled`,
    'Schedule delivery': (action) =>
      `Order is rescheduled to [${new DeliveryStateFormatter()._formatDate(
        action.scheduledAt,
        true
      )}]`,
    'Change delivery address': (after, before) =>
      `Customer address is updated from ${before} to ${after} `,
    'Change delivery address and Reschedule': (after, before) =>
      `Customer address is updated from ${before} to ${after} `,
    'Customer change phone number': ({ receiver_phone }) =>
      `Customer phone number is updated from [${receiver_phone.before}] to [${receiver_phone.after}]`,
    "We Tried to call your customer 1 Times, But he didn't Reply": () =>
      'Order received an exception: "Customer is not answering."',
    note: (note) => `A note is added: "${note}"`
  }
};

const whatsapp_state_logs = {
  WHATSAPP_ADDRESS_LOG: {
    header: 'Address Updated',
    log_message: (addressString) => `Address Updated ${addressString}`
  },
  WHATSAPP_SCHEDULED_DATE_LOG: {
    header: 'Order Reschedule',
    log_message: (new_delivery_date) =>
      `Order is rescheduled to ${new_delivery_date} `
  },
  WHATSAPP_ADDRESS_AND_SCHEDULED_DATE_LOG: 'Address and Schedule Updated',
  WHATSAPP_CONFIRM_EXCEPTION_LOG: 'Order Received an Exception',
  WHATSAPP_MESSAGE_SENDING_LOG: (sender) =>
    `Whatsapp bot sent a message to ${sender}`
};

const stateColor = {
  10: 'br-state-blue-group',
  11: 'br-state-blue-group',
  20: 'br-state-blue-group',
  20: 'br-state-blue-group',
  21: 'br-state-blue-group',
  22: 'br-state-blue-group',
  23: 'br-state-blue-group',
  24: 'br-state-blue-group',
  25: 'br-state-blue-group',
  30: 'br-state-blue-group',
  40: 'br-state-blue-group',
  41: 'br-state-blue-group',
  42: 'br-state-blue-group',
  43: 'br-state-blue-group',
  45: 'br-state-green-group',
  46: 'br-state-green-group',
  47: 'br-state-yellow-group',
  103: 'br-state-yellow-group',
  105: 'br-state-yellow-group',
  49.1: 'br-state-purple-group',
  102: 'br-state-yellow-group',
  48: 'br-state-purple-group',
  49: 'br-state-purple-group',
  104: 'br-state-purple-group',
  100: 'br-state-red-group',
  101: 'br-state-red-group',
  60: 'br-state-purple-group'
};

const mapOldStates = {
  'Pickup requested': 10,
  'Waiting for route': 11,
  'Route Assigned': 20,
  'Picked up from business': 21,
  'Picking up from consignee': 22,
  'Picked up from consignee': 23,
  'Received at warehouse': 24,
  'In transit between Hubs': 30,
  'Picking Up': 40,
  'Picking up': 40,
  'Picked up': 41,
  Exchanged: 42,
  Delivered: 45,
  'Returned to business': 46,
  Exception: 47,
  Terminated: 48,
  Canceled: 49,
  Lost: 100,
  Damaged: 101,
  Investigation: 102
};

class DeliveryStateFormatter {
  constructor() {
    this.isExchangeReturn = false;
    this.currentHubName = null;
    this.stateCodeAfter = 0;
    this.isSignAndReturnPickup = false;
  }

  getStateName = (item) => {
    let {
      type,
      type_before,
      state,
      trackingNumber,
      attemptsCount,
      outboundActions,
      holder,
      integrationInfo
    } = item;
    const useCourierStates =
      !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
      !this.orderReceivedAtVendorWarehouse(item);

    const statesObj = useCourierStates ? courierStates : states;

    if (!type || !state.code) return 'N/A';
    if (type === 'EXCHANGE-forward') type = 'EXCHANGE_forward';
    if (type === 'EXCHANGE-return') type = 'EXCHANGE_return';
    if (type === ORDER_TYPES_VALUE.SIGN_AND_RETURN) {
      return this.handleSignAndReturnStateName(
        item,
        statesObj,
        useCourierStates
      );
    }

    if (
      state?.code === DELIVERY_STATES_CODES.ON_HOLD &&
      state.childState === DELIVERY_CHILD_STATES.REJECTED_RETURN
    ) {
      return fmt({
        id: 'orders.order_new_statues.rejected_return'
      });
    }

    if (
      state?.code === DELIVERY_STATES_CODES.RETURNED_TO_BUSINESS &&
      INTERNATIONAL_ORDERS_RETURN_CHILD_STATE.includes(state.childState)
    ) {
      return fmt(
        {
          id: 'orders.order_new_statues.international_return'
        },
        {
          returnType: INTERNATIONAL_ORDERS_RETURN_LABEL[state.childState]
        }
      );
    }

    if (state?.code >= 48 && state?.code !== 49 && state?.code !== 60) {
      return statesObj.destructive[state.code]
        ? statesObj.destructive[state.code](holder, attemptsCount, type)
        : 'N/A';
    } else {
      if (
        (type === ORDER_TYPES_VALUE.CRP ||
          type === ORDER_TYPES_VALUE.CASH_COLLECTION) &&
        state.code === 49 &&
        state.canceled.reason === 'Uncovered Zone'
      ) {
        return statesObj[type][49.1];
      } else if (
        (type === ORDER_TYPES_VALUE.DELIVER ||
          type === 'EXCHANGE-forward' ||
          type === 'EXCHANGE-return' ||
          type === ORDER_TYPES_VALUE.EXCHANGE) &&
        state.code === 24 &&
        state.waitingForBusinessAction
      ) {
        return useCourierStates
          ? fmt({
              id: 'deliveries.states.received_at_bosta_warehouse'
            })
          : 'Received at warehouse-On Hold';
      } else if (
        state?.code === 24 &&
        state.childState ===
          DELIVERY_CHILD_STATES.WAITING_FOR_REJECTED_RETURN_ACTION
      ) {
        return fmt({
          id: useCourierStates
            ? 'deliveries.states.received_at_bosta_warehouse'
            : 'orders.order_new_statues.received_at_warehouse_rejected_return'
        });
      } else if (
        [ORDER_TYPES_VALUE.DELIVER, ORDER_TYPES_VALUE.RTO].includes(type) &&
        integrationInfo?.provider === PUDO_PROVIDER &&
        [24, 41, 45, 46].includes(state.code)
      ) {
        if (ORDER_TYPES_VALUE.DELIVER === type) {
          return statesObj[ORDER_TYPES_VALUE.DELIVER][
            state.code === 24
              ? state?.receivedAtWarehouse?.warehouse?.type === PUDO_HUB_TYPE
                ? PUDO_STATES.RECEIVED_AT_WAREHOUSE
                : 24
              : state.code === 41
              ? PUDO_STATES.READY_TO_PICK
              : PUDO_STATES.DELIVERD
          ];
        } else {
          return statesObj[ORDER_TYPES_VALUE.RTO][PUDO_STATES.CLOSED];
        }
      } else if (
        state.code === DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE &&
        state.childState === DELIVERY_CHILD_STATES.READY_TO_DISPATCH
      ) {
        return fmt({
          id: 'orders.order_new_statues.ready_to_dispatch'
        });
      } else if (
        state.code === DELIVERY_STATES_CODES.RECEIVED_AT_WAREHOUSE &&
        state.childState === DELIVERY_CHILD_STATES.AIR_TRANSIT
      ) {
        return fmt({
          id: 'orders.order_new_statues.air_transit'
        });
      }
      console.log(state?.code);

      return !statesObj[type]
        ? statesObj[ORDER_TYPES_VALUE.DELIVER][state.code]
        : statesObj[type][state.code]
        ? statesObj[type][state.code]
        : 'N/A';
    }
  };

  getStateColor = (delivery) => {
    const { state, type } = delivery;
    const stateName = this.getStateName(delivery);

    if (
      !isDefaultBostaCourier([...aclMatrix.THREE_PL, VENDOR_MANAGER]) &&
      !this.orderReceivedAtVendorWarehouse(delivery)
    ) {
      return stateColor[10];
    }

    if (
      stateName ===
      fmt({
        id: 'deliveries.states.canceled'
      })
    ) {
      return stateColor[100];
    }
    if (
      (type === ORDER_TYPES_VALUE.CRP ||
        type === ORDER_TYPES_VALUE.CASH_COLLECTION) &&
      state?.code === 49 &&
      state?.canceled?.reason === 'Uncovered Zone'
    ) {
      return stateColor[49.1];
    }

    if (
      (type === ORDER_TYPES_VALUE.DELIVER ||
        type === 'EXCHANGE-forward' ||
        type === 'EXCHANGE-return' ||
        type === ORDER_TYPES_VALUE.EXCHANGE ||
        type === ORDER_TYPES_VALUE.SIGN_AND_RETURN) &&
      state &&
      state?.code === 24 &&
      state?.waitingForBusinessAction &&
      state?.lastExceptionCode === 4
    ) {
      return stateColor[47];
    }

    if (
      state?.code === 24 &&
      state.childState ===
        DELIVERY_CHILD_STATES.WAITING_FOR_REJECTED_RETURN_ACTION
    ) {
      return stateColor[47];
    }

    if (state?.code === 46 && type === ORDER_TYPES_VALUE.RTO) {
      return stateColor[49.1];
    }

    return stateColor[state.code];
  };

  orderReceivedAtVendorWarehouse = (delivery) => {
    const vendorHubId = getVendorHubId();

    return (
      (delivery?.state?.receivedAtWarehouse?.warehouse?._id === vendorHubId &&
        delivery?.state?.code !== 30) ||
      delivery?.warehouseTransit?.destinationWarehouse?._id === vendorHubId
    );
  };

  formatLog = (delivery, proofOfReturnData) => {
    const formattedLogs = [];
    const {
      log: logs = [],
      type,
      attemptsCount,
      pendingPickup,
      outboundActions,
      holder,
      state,
      scheduledAt,
      firstHub,
      assignedHub,
      pricingPackageSize,
      trackingNumber,
      routeId,
      returnGroupId
    } = delivery;
    let deliveryType = this._getOriginalType(type, logs);
    let exceptionCounter = 1;
    const noOfExceptions = state?.exception?.length;
    logs.forEach((log) => {
      const { actionLevel, actionType, actionsList = {}, takenBy, time } = log;

      const sealNumber = actionsList?.warehouseTransit?.after?.sealNumber;
      const formattedObject = {};
      let suitableLog = '';
      let isStar = false;
      if (actionsList && actionsList.state_code) {
        // eslint-disable-next-line prefer-const
        let { after, before } = actionsList.state_code;
        this.stateCodeAfter = after;
        after = this._getTheRightAfterStateCode(
          after,
          before,
          deliveryType,
          actionsList,
          pendingPickup,
          state
        );
        if (after === 47 || after === 47.1) {
          if (actionsList.state_exception.after.code) {
            suitableLog = statesLogs[deliveryType][after]
              ? statesLogs[deliveryType][after][
                  actionsList.state_exception.after.code
                ]
              : 'N/A';
          } else {
            suitableLog = statesLogs[deliveryType][after]
              ? statesLogs[deliveryType][after].default(
                  actionsList.state_exception.after.reason
                )
              : 'N/A';
          }

          isStar = true;
        } else if (
          [ACTION_TYPES.UPDATE, ACTION_TYPES.CREATE].includes(actionType)
        ) {
          if (
            after === 42 &&
            deliveryType === ORDER_TYPES_VALUE.SIGN_AND_RETURN
          ) {
            formattedLogs.push({
              date: this._formatDate(time),
              action: statesLogs[deliveryType][42.1](scheduledAt),
              takenBy: this._formatTakenBy()
            });
          }
          suitableLog =
            after >= 48 && after !== 60
              ? statesLogs.destructive[after]
                ? statesLogs.destructive[after](
                    actionsList.state_lost?.after.user.type ||
                      actionsList.state_damaged?.after.user.type ||
                      takenBy?.userRole,
                    attemptsCount,
                    outboundActions,
                    holder,
                    deliveryType,
                    delivery,
                    actionsList
                  )
                : 'N/A'
              : statesLogs[deliveryType][after]
              ? after === 42 &&
                deliveryType === ORDER_TYPES_VALUE.SIGN_AND_RETURN
                ? statesLogs[deliveryType][after](scheduledAt)
                : statesLogs[deliveryType][after]
              : 'N/A';
        }
      } else if (
        actionsList &&
        (actionsList.state || actionsList.state_value)
      ) {
        let afterCode = this._getStateCode(
          actionsList.state
            ? actionsList.state.after
            : actionsList.state_value.after
        );
        this.stateCodeAfter = afterCode;
        const beforeCode = this._getStateCode(
          actionsList.state
            ? actionsList.state.before
            : actionsList.state_value.before
        );
        afterCode = this._getTheRightAfterStateCode(
          afterCode,
          beforeCode,
          deliveryType,
          actionsList,
          pendingPickup,
          state
        );
        if (afterCode === 47 || afterCode === 47.1) {
          if (actionsList.state_exception.after.code) {
            suitableLog = statesLogs[deliveryType][afterCode]
              ? statesLogs[deliveryType][afterCode][
                  actionsList.state_exception.after.code
                ]
              : 'N/A';
          } else {
            suitableLog = statesLogs[deliveryType][afterCode]
              ? statesLogs[deliveryType][afterCode].default(
                  actionsList.state_exception.after.reason
                )
              : 'N/A';
          }
          isStar = true;
        } else {
          suitableLog =
            afterCode >= 48
              ? statesLogs.destructive[afterCode]
                ? statesLogs.destructive[afterCode](
                    actionsList.state_lost?.after.user.type ||
                      actionsList.state_damaged?.after.user.type,
                    attemptsCount,
                    outboundActions,
                    holder,
                    delivery
                  )
                : 'N/A'
              : statesLogs[deliveryType][afterCode]
              ? statesLogs[deliveryType][afterCode]
              : 'N/A';
        }
      } else if (actionsList?.outboundActions) {
        const { length } = actionsList.outboundActions.after;
        const lastOutBoundAction =
          actionsList.outboundActions.after[length - 1];
        if (actionsList.state_exception_0_fakeAttempt) {
          this._handleFakeAttempt(formattedLogs, time, takenBy);
        }
        if (
          lastOutBoundAction.exceptionCode == 11 ||
          lastOutBoundAction.exceptionCode == 12
        ) {
          formattedLogs.push({
            date: this._formatDate(time),
            action:
              statesLogs.OUTBOUND_ACTIONS[lastOutBoundAction.exceptionCode],
            takenBy: this._formatTakenBy(takenBy, 2)
          });
        }
        if (
          lastOutBoundAction.action ===
            'Change delivery address and Reschedule' ||
          lastOutBoundAction.action === 'Change delivery address'
        ) {
          if (lastOutBoundAction.scheduledAt) {
            formattedLogs.push({
              date: this._formatDate(time),
              action:
                statesLogs.OUTBOUND_ACTIONS['Schedule delivery'](
                  lastOutBoundAction
                ),
              takenBy: this._formatTakenBy(takenBy, 2)
            });
          }
          const afterAddress = this._formatAfterAddress(
            lastOutBoundAction.customerAddress
          );
          const beforeAddress = this._formatBeforeAddress(actionsList);

          formattedLogs.push({
            date: this._formatDate(time),
            action: statesLogs.OUTBOUND_ACTIONS[lastOutBoundAction.action](
              afterAddress,
              beforeAddress
            ),
            takenBy: this._formatTakenBy(takenBy, 2)
          });
        } else if (
          lastOutBoundAction.action !== 'Redispatch tomorrow' &&
          lastOutBoundAction.action !== 'Schedule delivery'
        ) {
          let ScheduleAttemptLog;
          let outBoundObject;
          if (
            lastOutBoundAction.scheduledAt &&
            lastOutBoundAction.action !== 'Return to shipper'
          ) {
            ScheduleAttemptLog =
              statesLogs.OUTBOUND_ACTIONS['Schedule delivery'](
                lastOutBoundAction
              );
            outBoundObject = {
              date: this._formatDate(time),
              action: ScheduleAttemptLog,
              takenBy: this._formatTakenBy(takenBy, 2)
            };
            formattedLogs.push(outBoundObject);
          }

          ScheduleAttemptLog =
            statesLogs.OUTBOUND_ACTIONS[lastOutBoundAction.action](actionsList);
          outBoundObject = {
            date: this._formatDate(time),
            action: ScheduleAttemptLog,
            takenBy: this._formatTakenBy(takenBy, 2)
          };
          formattedLogs.push(outBoundObject);
        } else {
          const ScheduleAttemptLog =
            statesLogs.OUTBOUND_ACTIONS[lastOutBoundAction.action](
              lastOutBoundAction
            );
          const outBoundObject = {
            date: this._formatDate(time),
            action: ScheduleAttemptLog,
            takenBy: this._formatTakenBy(takenBy, 2)
          };

          formattedLogs.push(outBoundObject);
        }

        if (actionsList.onHold_actionNeeded?.after.notes) {
          this._handleNoteLog(
            formattedLogs,
            time,
            takenBy,
            actionsList.onHold_actionNeeded?.after.notes
          );
        }
      } else if (actionsList?.pendingPickup?.after) {
        suitableLog = statesLogs[deliveryType][10.2]
          ? statesLogs[deliveryType][10.2]
          : 'N/A';
      } else if (actionsList?.state_businessActionTaken) {
        const after = this._getTheRightAfterStateCode(
          0,
          0,
          deliveryType,
          actionsList,
          pendingPickup,
          state
        );
        const stateCode = this.isSignAndReturnPickup ? 47.1 : 47;
        if (after) {
          if (actionsList?.state_businessActionTaken.after !== 2) {
            if (
              deliveryType === ORDER_TYPES_VALUE.SIGN_AND_RETURN &&
              (after === 13.1 || after === 14.1)
            ) {
              suitableLog = statesLogs[deliveryType][stateCode]
                ? after === 13.1
                  ? statesLogs[deliveryType][stateCode][after](
                      this._formatBeforeAddress(actionsList),
                      this._formatBeforeAddress(actionsList, true)
                    )
                  : statesLogs[deliveryType][stateCode][after](
                      actionsList?.receiver_secondPhone?.after
                    )
                : 'N/A';
            } else {
              suitableLog = statesLogs[deliveryType][stateCode]
                ? statesLogs[deliveryType][stateCode][after]
                : 'N/A';
            }
          } else {
            const log = statesLogs[ORDER_TYPES_VALUE.RTO][47]
              ? statesLogs[ORDER_TYPES_VALUE.RTO][47][after]
              : 'N/A';
            formattedLogs.push({
              date: this._formatDate(time),
              action: log,
              takenBy: this._formatTakenBy(takenBy)
            });
          }
        }
      }
      if (actionsList?.rate) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: this._formatRateLog(actionsList),
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (actionsList?.state_receivedAtWarehouse_warehouse) {
        const sealBefore = actionsList?.sealNumber?.before;
        const sealAfter = actionsList?.sealNumber?.after;
        const isRemoved = sealBefore != null && sealAfter === null;
        formattedLogs.push({
          date: this._formatDate(time),
          action: isRemoved
            ? fmt(
                {
                  id: 'deliveries.delivery_details.delivery_logs.removed_from_seal'
                },
                { sealNumber: sealBefore }
              )
            : this._formateWareHouseChangeLog({
                warehouse: actionsList?.state_receivedAtWarehouse_warehouse
              }),
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (actionsList?.assignedHub_name) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: this._formateWareHouseChangeLog({
            warehouse: actionsList?.assignedHub_name,
            isAssignedHubChange: true
          }),
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (
        actionsList?.pickupRequestId?.after &&
        delivery.type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
      ) {
        this.isSignAndReturnPickup = true;
      }
      if (actionsList?.cod_amount) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `COD Updated ${
            takenBy?.userRole === BUSINESS_ADMIN
              ? 'as per customer’s request'
              : ''
          } ${
            actionsList?.linkedTickets?.after
              ? `as per customer’s request (Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy),
          extraInfo: `COD changed From ${
            actionsList.cod_amount.before || 0
          } To ${actionsList.cod_amount.after}`
        });
      }
      if (actionsList?.receiver_phone) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Phone Number Updated ${
            takenBy?.userRole === BUSINESS_ADMIN
              ? 'as per customer’s request'
              : ''
          } ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy),

          extraInfo: `Customer Phone Number changed from ${
            actionsList.receiver_phone.before || 'N/A'
          } to ${actionsList.receiver_phone.after}`
        });
      }
      if (actionsList?.receiver_secondPhone) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Second Phone Number Updated ${
            takenBy?.userRole === BUSINESS_ADMIN
              ? 'as per customer’s request'
              : ''
          } ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy),

          extraInfo: `Customer Second Phone Number changed from ${
            actionsList.receiver_secondPhone.before || 'N/A'
          } to ${actionsList.receiver_secondPhone.after}`
        });
      }
      if (
        actionType !== WHATSAPP_SCHEDULED_DATE_LOG &&
        actionsList?.scheduledAt &&
        !actionsList?.sla_e2eSla &&
        !actionsList?.sla_orderSla &&
        !actionsList?.onHold_activity &&
        !actionsList?.sla
      ) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Order has been rescheduled to ${this._formatDate(
            actionsList?.scheduledAt.after,
            true
          )} ${
            takenBy?.userRole === BUSINESS_ADMIN
              ? 'as per customer’s request'
              : ''
          } ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (actionsList?.state_receivedAtWarehouse_location) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Order has been moved to the bin ${actionsList?.state_receivedAtWarehouse_location?.after}`,
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (actionsList?.allowToOpenPackage) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `${
            takenBy?.userRole === BUSINESS_ADMIN
              ? `Consignee is now ${
                  actionsList?.allowToOpenPackage?.after
                    ? 'allowed'
                    : 'not allowed'
                } to open package as per customer’s request.`
              : `Allow opening package was updated to ${
                  actionsList?.allowToOpenPackage?.after ? 'Yes' : 'No'
                }`
          } ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy)
        });
      }

      if (
        actionsList?.state_childState?.after ===
        DELIVERY_CHILD_STATES.READY_TO_DISPATCH
      ) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Order is ready to dispatch`,
          takenBy: this._formatTakenBy(takenBy)
        });
      }

      if (!isNil(actionsList?.consigneeStatedAttemptWasFake)) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `This exception is ${
            actionsList.consigneeStatedAttemptWasFake ? 'fake' : 'valid'
          }`,
          extraInfo:
            'Exception was validated by the consignee through WhatsApp',
          takenBy: this._formatTakenBy(takenBy)
        });
      }

      if (actionsList?.refusalReason) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: 'Refusal reason was added by the consignee through WhatsApp',
          extraInfo:
            REFUSAL_OPTIONS[actionsList.refusalReason] ||
            actionsList.refusalReason,
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (actionType === ACTION_TYPES.PRINT_AWB) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: fmt({
            id: 'deliveries.delivery_details.delivery_logs.order_repacked'
          }),
          takenBy: this._formatTakenBy(takenBy)
        });
      }
      if (
        actionsList?.linkedTickets?.after?.type === CANCELLATION_REQUEST &&
        !actionsList?.state_terminated
      ) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: actionsList?.linkedTickets?.after?.directBusinessRequest
            ? 'Cancellation request has been added based on business request'
            : `Cancellation request has been added ${
                actionsList?.linkedTickets?.after?.number
                  ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
                  : ``
              }`,
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (actionType === ACTION_TYPES.CHANGE_REQUEST_CREATED) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `A request to change package COD was submitted.`,
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (actionType === ACTION_TYPES.CHANGE_REQUEST_REJECTED) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `A request to change package COD was rejected.`,
          extraInfo: `Reason: ${
            REJECTION_REASONS[actionsList?.rejectionCode] ||
            actionsList?.rejectionReason
          }`,
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (actionType === ACTION_TYPES.SCAN) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: 'Scan Action',
          takenBy: ['', log?.takenBy?.userName]
        });
      } else if (actionType === ACTION_TYPES.CONFRIMED_PRINTING_AWB) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: 'Confirmed printing AWB',
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (actionType === ACTION_TYPES.ADD_STAR_INSTRUCTION) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: 'A note has been sent to the star based on business request.',
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (actionType === ACTION_TYPES.RESTORE) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: 'Order is restored',
          takenBy: this._formatTakenBy(takenBy)
        });
      } else if (
        [
          ACTION_TYPES.ESCALATE_DELIVERY_LOG,
          ACTION_TYPES.DELETE_DELIVERY_ESCALATION_LOG
        ].includes(actionType)
      ) {
        formattedLogs.push({
          date: this._formatDate(time),
          action:
            actionType === ACTION_TYPES.DELETE_DELIVERY_ESCALATION_LOG
              ? 'Order escalation is removed'
              : `Order is escalated to severity level ${
                  actionsList?.severityLevel ||
                  actionsList?.escalationInfo?.severityLevel
                }`,
          takenBy: this._formatTakenBy(takenBy)
        });
      }

      const isAddressChanged = Object.keys(actionsList).filter(
        (action) =>
          action.includes('dropOffAddress') || action.includes('pickupAddress')
      );
      if (isAddressChanged?.length && actionType !== WHATSAPP_ADDRESS_LOG) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Address Updated ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`,
          takenBy: this._formatTakenBy(takenBy),

          extraInfo: `Address changed From [${this._formatBeforeAddress(
            actionsList
          )}]  To [${this._formatBeforeAddress(actionsList, true)}]`
        });
      }

      if (actionsList?.changedToRTODate) {
        if (!actionsList.changedToRTODate.after) {
          formattedLogs.push({
            date: this._formatDate(time),
            action: statesLogs[ORDER_TYPES_VALUE.RTO].redispatch_order,
            takenBy: this._formatTakenBy(takenBy)
          });
          if (actionsList?.type?.after)
            deliveryType =
              actionsList?.type?.after === ORDER_TYPES_VALUE.FXF_SEND
                ? ORDER_TYPES_VALUE.DELIVER
                : actionsList?.type?.after;
        } else {
          formattedLogs.push({
            date: this._formatDate(time),
            action:
              attemptsCount >= getMaxNumOfAttempts().DELIVERY
                ? statesLogs[ORDER_TYPES_VALUE.RTO].change_to_rto_3_attempts
                : statesLogs[ORDER_TYPES_VALUE.RTO].change_to_rto(
                    actionsList.assignedHub?.after.name ||
                      actionsList.assignedHub_name?.after
                  ),
            takenBy:
              attemptsCount >= getMaxNumOfAttempts().DELIVERY
                ? this._formatTakenBy(null)
                : this._formatTakenBy(takenBy)
          });
          deliveryType = ORDER_TYPES_VALUE.RTO;
        }
      }

      if (suitableLog) {
        formattedObject.date = this._formatDate(time);
        if (checkIfLogIsException(suitableLog)) {
          if (
            actionsList?.state_exception?.after?.attemptType ===
            getAttemptsTypes().RETURN
          ) {
            formattedObject.action = 'Order Received a Return Exception';
          } else {
            formattedObject.action = 'Order Received an Exception';
            formattedObject.proofOfException =
              actionsList?.exceptionProofUrl?.after;
          }

          if (checkIfLogIsCancel(suitableLog)) {
            formattedObject.bannerColor = 'danger';
          } else {
            formattedObject.bannerColor = 'warning';
          }
          formattedObject.extraInfo = suitableLog;
        } else if (checkIfLogIsCancel(suitableLog)) {
          formattedObject.action = `Order Canceled ${
            actionsList?.linkedTickets?.after
              ? `(Ticket Number Ref: ${actionsList?.linkedTickets?.after?.number})`
              : ``
          }`;

          formattedObject.bannerColor = 'danger';

          formattedObject.extraInfo = suitableLog;
        } else {
          formattedObject.action = suitableLog;
          if (
            suitableLog === statesLogs[deliveryType][24] &&
            delivery?.creationSrc?.includes(SOHO)
          ) {
            formattedObject.action = `Security seal ${delivery?.securitySeal?.code} for order ${trackingNumber} is scanned and order is received at warehouse`;
          }
          if (suitableLog === statesLogs[deliveryType][30] && sealNumber) {
            formattedObject.extraInfo = `Seal Number: ${sealNumber}`;
          } else if (suitableLog === statesLogs[deliveryType][46]) {
            if (proofOfReturnData?.returnContactPersonName) {
              formattedObject.extraInfo = `Handed to: ${proofOfReturnData?.returnContactPersonName} (${proofOfReturnData?.returnContactPersonPhone})`;
            } else {
              formattedObject.por = {
                isReturned: true,
                data: proofOfReturnData
              };
            }

            if (routeId && returnGroupId) {
              formattedObject.returnManifest = {
                routeId,
                returnGroupId
              };
            }
          } else if (
            [
              statesLogs[deliveryType][22],
              statesLogs[deliveryType][41],
              statesLogs[deliveryType][40]
            ].includes(suitableLog)
          ) {
            formattedObject.extraInfo = `Order assigned to Star: ${
              actionsList?.star
                ? actionsList?.star?.after?.name
                : delivery?.star?.name
            }`;
          }
        }
        formattedObject.takenBy = this._formatTakenBy(takenBy, isStar ? 1 : 0);
        formattedObject.hub = this._formatHubName(
          assignedHub,
          firstHub,
          actionsList
        );
        if (actionsList?.firstHub_isScanned) {
          if (formattedObject?.hub) {
            formattedObject.hub = null;
          }
          formattedObject.extraInfo = (
            <>
              <div>
                {`Mode: ${
                  actionsList?.firstHub_isScanned.after
                    ? 'Size Scanned'
                    : 'Size Selected'
                }`}
              </div>
              <div> {`Size Value : ${pricingPackageSize?.name}`}</div>
            </>
          );
        }
        formattedObject.hub = this._formatHubName(actionsList);
        if (actionsList?.onHold_attemptNumber?.after) {
          const exceptionIndex = noOfExceptions - exceptionCounter;
          formattedObject.liveOpsLogAttemptNumber =
            actionsList?.onHold_attemptNumber?.after || null;
          formattedObject.showExceptionDeatils = true;
          formattedObject.whatsAppVerification =
            state?.exception[exceptionIndex]?.whatsAppVerification?.verified;
          formattedObject.whatsappFakeAttempt =
            state?.exception[exceptionIndex]?.whatsAppVerification?.fakeAttempt;
        }
        actionsList?.onHold_attemptNumber?.after && exceptionCounter++;
        formattedLogs.push(formattedObject);
      }
      if (actionsList?.cod) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `COD Updated`,
          takenBy: this._formatTakenBy(takenBy),

          extraInfo: `COD amount is added with value ${actionsList.cod.after}`
        });
      }
      if (actionLevel === WHATSAPP_ACTION_LEVEL) {
        let formattedObject = {
          date: this._formatDate(time),
          takenBy: ['By', 'WhatsApp Bot'],
          isWhatsappLog: true
        };
        if (actionType === WHATSAPP_ADDRESS_LOG) {
          formattedObject = {
            ...formattedObject,
            action: whatsapp_state_logs[actionType]['header'],
            actionDetail: whatsapp_state_logs[actionType].log_message(
              `From [${this._formatBeforeAddress(
                actionsList
              )}]  To [${this._formatBeforeAddress(actionsList, true)}]`
            )
          };
        } else if (actionType === WHATSAPP_SCHEDULED_DATE_LOG) {
          formattedObject = {
            ...formattedObject,
            action: whatsapp_state_logs[actionType]['header'],
            actionDetail: whatsapp_state_logs[actionType].log_message(
              this._formatDate(actionsList?.scheduledAt?.after, true)
            )
          };
        }
        if (
          actionType === WHATSAPP_ADDRESS_LOG ||
          actionType === WHATSAPP_SCHEDULED_DATE_LOG
        ) {
          formattedLogs.push(formattedObject);
        }
      }
      if (actionsList?.securitySeal_code) {
        formattedLogs.push({
          date: this._formatDate(time),
          action: `Security seal for order ${trackingNumber} has been changed from #${actionsList.securitySeal_code?.before} to #${actionsList.securitySeal_code?.after} `,
          takenBy: this._formatTakenBy(takenBy)
        });
      }
    });
    return formattedLogs;
  };

  handleSignAndReturnStateName = (item, statesObj, useCourierStates) => {
    const { state, holder, collectedFromConsignee, pickupRequestType } = item;
    if (state.code === 24 && state.waitingForBusinessAction) {
      return useCourierStates
        ? fmt({
            id: 'deliveries.states.received_at_bosta_warehouse'
          })
        : 'Received at warehouse-On Hold';
    }

    if (state?.code >= 48 && state?.code !== 49 && state?.code !== 60) {
      return statesObj.destructive[state.code]
        ? statesObj.destructive[state.code](holder)
        : state.value;
    }
    if (!pickupRequestType && !collectedFromConsignee) {
      return statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].deliver[state.code]
        ? statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].deliver[state.code]
        : state.value;
    } else if (
      pickupRequestType === SIGN_AND_RETURN_PICKUP &&
      !collectedFromConsignee
    ) {
      return statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].pickup[state.code]
        ? statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].pickup[state.code]
        : state.value;
    } else if (
      state.code === 23 &&
      collectedFromConsignee &&
      pickupRequestType !== SIGN_AND_RETURN_PICKUP
    ) {
      return statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].deliver[state.code]
        ? statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].deliver[state.code]
        : state.value;
    } else {
      if (state.code === 46) {
        return statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].pickup[state.code]
          ? statesObj[ORDER_TYPES_VALUE.SIGN_AND_RETURN].pickup[state.code]
          : state.value;
      }
      return statesObj[ORDER_TYPES_VALUE.RTO][state.code]
        ? statesObj[ORDER_TYPES_VALUE.RTO][state.code]
        : state.value;
    }
  };

  _handleFormatTimeSlot = (timeSlot) => {
    if (timeSlot?.endTime && timeSlot?.startTime) {
      const startDateAMPM = timeSlot?.startTime >= 12 ? 'PM' : 'AM';
      const endDateAMPM = timeSlot?.endTime >= 12 ? 'PM' : 'AM';
      const startTime =
        timeSlot?.startTime === 12 ? 12 : timeSlot?.startTime % 12;
      const endTime = timeSlot?.endTime === 12 ? 12 : timeSlot?.endTime % 12;

      return `from ${startTime} ${startDateAMPM} to ${endTime} ${endDateAMPM}`;
    }
    return null;
  };

  handleDeliveryHighlight = (delivery) => {
    const dates = [
      {
        name: 'Scheduled At',
        value: delivery?.scheduledAt,
        timeSlot: delivery?.timeSlot
          ? this._handleFormatTimeSlot(delivery?.timeSlot)
          : null,
        isDate: true
      },
      {
        name: 'Cancelation Date',
        value:
          this.getStateName(delivery) === 'Canceled'
            ? delivery?.state?.exception?.length
              ? delivery?.state?.exception[0].time
              : delivery?.state?.canceled?.time
            : null,
        isDate: true
      },
      {
        name: 'RTO Date',
        value: delivery?.changedToRTODate,
        isDate: true
      },

      {
        name: 'Investigation Date',
        value: delivery?.state?.investigationAt,
        isDate: true
      },

      {
        name: 'Lost Date',
        value: delivery.state?.lost?.time,
        extraData: delivery?.state?.receivedAtWarehouse?.warehouse?.name,
        isDate: true
      },

      {
        name: 'Damaged Date',
        value: delivery?.state?.damaged?.time,
        extraData: delivery?.state?.receivedAtWarehouse?.warehouse?.name,
        isDate: true
      },
      {
        name: 'Deleted Date',
        value: delivery?.state?.terminated,
        isDate: true
      },
      delivery.type !== ORDER_TYPES_VALUE.DELIVER &&
      delivery.type !== ORDER_TYPES_VALUE.RTO
        ? {
            name:
              delivery?.type === ORDER_TYPES_VALUE.CRP
                ? 'Picked up from consignee Date'
                : delivery?.type === ORDER_TYPES_VALUE.EXCHANGE
                ? 'Exchange Date'
                : 'Pickup from consignee Date',
            value:
              delivery?.type === ORDER_TYPES_VALUE.SIGN_AND_RETURN
                ? delivery?.pickupRequestType === SIGN_AND_RETURN_PICKUP
                  ? delivery?.scheduledAt
                  : delivery?.collectedFromConsignee
                : delivery?.collectedFromConsignee,
            isDate: true
          }
        : null,
      delivery?.type !== ORDER_TYPES_VALUE.DELIVER &&
      delivery?.type !== ORDER_TYPES_VALUE.RTO
        ? {
            name:
              delivery?.type === ORDER_TYPES_VALUE.CRP
                ? 'Returned Date'
                : delivery?.type === ORDER_TYPES_VALUE.EXCHANGE
                ? 'Exchanged & Returned Date'
                : 'Signed & Collected Date',
            value: delivery?.state?.returnedToBusiness,
            isDate: true
          }
        : null,
      delivery?.type === ORDER_TYPES_VALUE.DELIVER
        ? {
            name: 'Delivery Date',
            value: delivery?.state?.delivering?.time,
            isDate: true
          }
        : null,

      {
        name: 'Return to Origin Date',
        value:
          delivery?.type === ORDER_TYPES_VALUE.RTO
            ? delivery?.state?.returnedToBusiness
            : null,
        isDate: true
      },
      {
        name: 'E2E SLA ends at',
        value: delivery?.sla?.e2eSla?.e2eSlaTimestamp,
        isDate: true
      },
      {
        name: 'Star Rating',
        value: delivery?.rate ? `${delivery.rate}/10` : 'N/A',
        isDate: false
      },
      {
        name: 'Consignee Feedback',
        value: delivery?.rateReason || 'N/A',
        isDate: false,
        extraData: delivery?.rateAddedBy
          ? `Added By: ${delivery?.rateAddedBy?.userName}`
          : 'N/A'
      }
    ];

    return dates;
  };

  _formatHubName(actionsList) {
    if (actionsList?.state_receivedAtWarehouse_warehouse?.after) {
      this.currentHubName =
        actionsList?.state_receivedAtWarehouse_warehouse.after.name;
    } else if (actionsList?.warehouseTransit?.after?.destinationWarehouse) {
      this.currentHubName =
        actionsList?.warehouseTransit.after.destinationWarehouse.name;
    } else if (actionsList?.assignedHub_name) {
      this.currentHubName = actionsList?.assignedHub_name?.after;
    }
    return this.currentHubName;
  }

  _formateWareHouseChangeLog({ warehouse, isAssignedHubChange } = {}) {
    return `${
      isAssignedHubChange ? 'Assigned warehouse' : 'Warehouse'
    } has been changed to "${
      warehouse?.after?.name || warehouse?.after || 'N/A'
    }" from "${warehouse?.before?.name || warehouse.before || 'N/A'}"`;
  }

  _handleFakeAttempt = (formattedLogs, time, takenBy) => {
    formattedLogs.push({
      date: this._formatDate(time),
      action: statesLogs.OUTBOUND_ACTIONS.fake_attempt,
      takenBy: this._formatTakenBy(takenBy, 2)
    });
  };

  _handleNoteLog = (formattedLogs, time, takenBy, note) => {
    formattedLogs.push({
      date: this._formatDate(time),
      action: statesLogs.OUTBOUND_ACTIONS.note(note),
      takenBy: this._formatTakenBy(takenBy, 2)
    });
  };

  _getTheRightAfterStateCode = (
    after,
    before,
    type,
    actionsList,
    pendingPickup,
    state
  ) => {
    switch (type) {
      case ORDER_TYPES_VALUE.DELIVER:
        if (after === 10 && before === 21) {
          after = 10.1;
        } else if (after === 24 && before === 47) {
          after = 24.1;
        } else if (after === 24 && before === 45) {
          after = 24.2;
        } else if (after === 49) {
          if (actionsList.state_canceled.after.reason === 'Uncovered Zone') {
            after = 1;
          } else if (actionsList.state_canceled.after.reason === BAD_ADDRESS) {
            after = 1.2;
          } else {
            after = 1.1;
          }
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 1
        ) {
          after = 4.1;
        } else if (actionsList.state_businessActionTaken?.after === 2) {
          after = 4.2;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 3
        ) {
          after = 13.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 14
        ) {
          after = 14.2;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 13
        ) {
          after = 13.2;
        }

        return after;
      case ORDER_TYPES_VALUE.CASH_COLLECTION:
        if (after === 11 && before === 47) {
          after = 11.1;
        } else if (after === 49) {
          if (actionsList.state_canceled.after.reason === 'Uncovered Zone') {
            after = 1;
          } else if (actionsList.state_canceled.after.reason === BAD_ADDRESS) {
            after = 1.2;
          } else {
            after = 1.1;
          }
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 3
        ) {
          after = 13.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 14
        ) {
          after = 14.2;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 13
        ) {
          after = 13.2;
        }
        return after;
      case ORDER_TYPES_VALUE.RTO:
        if (after === 10 && before === 21) {
          after = 10.1;
        } else if (
          after === 24 &&
          INVESTIGATION_EXCEPTIONS_CODES.includes(state.lastExceptionCode)
        ) {
          after = 24.3;
        } else if (after === 24 && before === 47) {
          after = 24.1;
        } else if (after === 24 && before === 45) {
          after = 24.2;
        } else if (after === 20 && state.waitingForBusinessAction) {
          after = 20.1;
        } else if (after === 49) {
          if (actionsList.state_canceled.after.reason === 'Uncovered Zone') {
            after = 1;
          } else if (actionsList.state_canceled.after.reason === BAD_ADDRESS) {
            after = 1.2;
          } else {
            after = 1.1;
          }
        } else if (actionsList.state_businessActionTaken?.after === 2) {
          after = 4.2;
        }
        return after;
      case ORDER_TYPES_VALUE.CRP:
        if (after === 10 && before === 22) {
          after = 10.1;
        } else if (after === 10 && before === 47) {
          after = 10.2;
        } else if (after === 24 && before === 47) {
          after = 24.1;
        } else if (after === 10 && (before === 24 || before === 23)) {
          after = 10.3;
        } else if (after === 49) {
          if (actionsList.state_canceled.after.reason === 'Uncovered Zone') {
            after = 1;
          } else if (actionsList.state_canceled.after.reason === BAD_ADDRESS) {
            after = 1.2;
          } else {
            after = 1.1;
          }
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 3
        ) {
          after = 13.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 14
        ) {
          after = 14.2;
        } else if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 13
        ) {
          after = 13.2;
        }
        return after;
      case ORDER_TYPES_VALUE.EXCHANGE:
        if (after === 10 && before === 21) {
          after = 10.1;
        }
        if (after === 24 && before === 47) {
          after = 24.1;
        }

        if (after === 49) {
          if (actionsList.state_canceled.after.reason === 'Uncovered Zone') {
            after = 1;
          } else if (actionsList.state_canceled.after.reason === BAD_ADDRESS) {
            after = 1.2;
          } else {
            after = 1.1;
          }
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 1
        ) {
          after = 4.1;
        }
        if (actionsList.state_businessActionTaken?.after === 2) {
          after = 4.2;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 3
        ) {
          after = 13.1;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 14
        ) {
          after = 14.2;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 13
        ) {
          after = 13.2;
        }
        if (after === 23) {
          this.isExchangeReturn = true;
        }

        return after;
      case ORDER_TYPES_VALUE.SIGN_AND_RETURN:
        if (after === 20 && this.isSignAndReturnPickup) {
          after = 20.1;
        }
        if (after === 22 && this.isSignAndReturnPickup) {
          after = 22.1;
        }

        if (after === 23 && this.isSignAndReturnPickup) {
          after = 23.1;
        }

        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 1
        ) {
          after = 4.1;
        }
        if (actionsList.state_businessActionTaken?.after === 2) {
          after = 4.2;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 3
        ) {
          after = 13.1;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_businessActionTaken?.after === 4
        ) {
          after = 14.1;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 14
        ) {
          after = 14.2;
        }
        if (
          !actionsList.state_waitingForBusinessAction?.after &&
          actionsList.state_exception?.after.code === 13
        ) {
          after = 13.2;
        }
        if (this.isSignAndReturnPickup && after === 47) {
          after = 47.1;
        }
        return after;
      default:
        return after;
    }
  };

  _getOriginalType = (type, logs) => {
    let originalType;
    if (type !== ORDER_TYPES_VALUE.RTO) {
      return type;
    }

    logs.forEach((log) => {
      if (
        log.actionsList &&
        log.actionsList.type &&
        log.actionsList.type.after === ORDER_TYPES_VALUE.RTO &&
        [
          ORDER_TYPES_VALUE.EXCHANGE,
          ORDER_TYPES_VALUE.DELIVER,
          ORDER_TYPES_VALUE.SIGN_AND_RETURN,
          ORDER_TYPES_VALUE.FXF_SEND
        ].includes(log.actionsList.type.before)
      ) {
        originalType = log.actionsList.type.before;
      }
    });
    if (originalType === ORDER_TYPES_VALUE.FXF_SEND)
      originalType = ORDER_TYPES_VALUE.DELIVER;

    return originalType;
  };

  _formatDate = (date, hideTime = false) => {
    let newDate;
    if (hideTime) {
      newDate = dayjs(date).tz().format('DD MMMM YYYY');
    } else {
      newDate = dayjs(date).tz().format('ddd, DD MMM YYYY  h:mm a');
    }
    return newDate;
  };

  _formatTakenBy = (takenBy, role = 0) => {
    if (takenBy) {
      const { userRole, userName } = takenBy;
      if (role === 1) {
        return ['Star', userName];
      }
      if (role === 2) {
        return ['Outbound', userName];
      }
      if (userName.split('-').length > 2) {
        return [formatRole(userRole), userName];
      }

      return [
        formatRole(userRole),
        userName.split('-')[1]?.includes('Hub')
          ? userName.split('-')[0]
          : userName
      ];
    }
    return ['System', ''];
  };

  _getStateCode = (state) => mapOldStates[state];

  _formatBeforeAddress = (actionsList, afterAddress = false) => {
    let formattedAddress = '';
    const {
      dropOffAddress_firstLine,
      dropOffAddress_secondLine,
      dropOffAddress_district_name,
      dropOffAddress_zone_name,
      dropOffAddress_city_name,
      dropOffAddress_apartment,
      dropOffAddress_buildingNumber,
      dropOffAddress_floor,
      dropOffAddress_isWorkAddress,
      pickupAddress_district_name,
      pickupAddress_firstLine,
      pickupAddress_secondLine,
      pickupAddress_zone_name,
      pickupAddress_city_name,
      pickupAddress_apartment,
      pickupAddress_buildingNumber,
      pickupAddress_floor,
      pickupAddress_isWorkAddress
    } = actionsList;
    if (
      dropOffAddress_city_name ||
      dropOffAddress_district_name ||
      dropOffAddress_firstLine ||
      dropOffAddress_secondLine ||
      dropOffAddress_zone_name ||
      dropOffAddress_apartment ||
      dropOffAddress_buildingNumber ||
      dropOffAddress_floor ||
      dropOffAddress_isWorkAddress
    ) {
      if (afterAddress) {
        if (dropOffAddress_firstLine?.after)
          formattedAddress += `first line:${dropOffAddress_firstLine.after}, `;
        if (dropOffAddress_secondLine?.after)
          formattedAddress += `second line:${dropOffAddress_secondLine.after}, `;
        if (dropOffAddress_district_name?.after)
          formattedAddress += `dist: ${dropOffAddress_district_name.after}, `;
        if (dropOffAddress_zone_name?.after)
          formattedAddress += `zone: ${dropOffAddress_zone_name.after}, `;
        if (dropOffAddress_city_name?.after)
          formattedAddress += `city : ${dropOffAddress_city_name.after}, `;
        if (dropOffAddress_apartment?.after)
          formattedAddress += `apartment : ${dropOffAddress_apartment.after}, `;
        if (dropOffAddress_buildingNumber?.after)
          formattedAddress += `building number : ${dropOffAddress_buildingNumber.after}, `;
        if (dropOffAddress_floor?.after)
          formattedAddress += `floor : ${dropOffAddress_floor.after}, `;
        if (dropOffAddress_isWorkAddress?.after)
          formattedAddress += `work address : ${
            dropOffAddress_isWorkAddress.after ? 'Yes' : 'No'
          }`;
      } else {
        if (dropOffAddress_firstLine?.before)
          formattedAddress += `first line:${dropOffAddress_firstLine.before}, `;
        if (dropOffAddress_secondLine?.before)
          formattedAddress += `second line:${dropOffAddress_secondLine.before}, `;
        if (dropOffAddress_district_name?.before)
          formattedAddress += `dist: ${dropOffAddress_district_name.before}, `;
        if (dropOffAddress_zone_name?.before)
          formattedAddress += `zone: ${dropOffAddress_zone_name.before}, `;
        if (dropOffAddress_city_name?.before)
          formattedAddress += `city : ${dropOffAddress_city_name.before}, `;
        if (dropOffAddress_apartment?.before)
          formattedAddress += `apartment : ${dropOffAddress_apartment.before}, `;
        if (dropOffAddress_buildingNumber?.before)
          formattedAddress += `building number : ${dropOffAddress_buildingNumber.before}, `;
        if (dropOffAddress_floor?.before)
          formattedAddress += `floor : ${dropOffAddress_floor.before}, `;
        if (dropOffAddress_isWorkAddress?.before)
          formattedAddress += `work address : ${
            dropOffAddress_isWorkAddress.beforee ? 'Yes' : 'No'
          }`;
      }
    } else {
      if (afterAddress) {
        if (pickupAddress_firstLine?.after)
          formattedAddress += `first line:${pickupAddress_firstLine.after}, `;
        if (pickupAddress_secondLine?.after)
          formattedAddress += `second line:${pickupAddress_secondLine.after}, `;
        if (pickupAddress_district_name?.after)
          formattedAddress += `dist: ${pickupAddress_district_name.after}, `;
        if (pickupAddress_zone_name?.after)
          formattedAddress += `zone: ${pickupAddress_zone_name.after}, `;
        if (pickupAddress_city_name?.after)
          formattedAddress += `city : ${pickupAddress_city_name.after}, `;
        if (pickupAddress_apartment?.after)
          formattedAddress += `apartment : ${pickupAddress_apartment.after}, `;
        if (pickupAddress_buildingNumber?.after)
          formattedAddress += `building number : ${pickupAddress_buildingNumber.after}, `;
        if (pickupAddress_floor?.after)
          formattedAddress += `floor : ${pickupAddress_floor.after}, `;
        if (pickupAddress_isWorkAddress?.after)
          formattedAddress += `work address : ${
            pickupAddress_isWorkAddress.after ? 'Yes' : 'No'
          }`;
      } else {
        if (pickupAddress_firstLine?.before)
          formattedAddress += `first line:${pickupAddress_firstLine.before}, `;
        if (pickupAddress_secondLine?.before)
          formattedAddress += `second line:${pickupAddress_secondLine.before}, `;
        if (pickupAddress_district_name?.before)
          formattedAddress += `dist: ${pickupAddress_district_name.before}, `;
        if (pickupAddress_zone_name?.before)
          formattedAddress += `zone: ${pickupAddress_zone_name.before}, `;
        if (pickupAddress_city_name?.before)
          formattedAddress += `city : ${pickupAddress_city_name.before}, `;
        if (pickupAddress_apartment?.before)
          formattedAddress += `apartment : ${pickupAddress_apartment.before}, `;
        if (pickupAddress_buildingNumber?.before)
          formattedAddress += `building number : ${pickupAddress_buildingNumber.before}, `;
        if (pickupAddress_floor?.before)
          formattedAddress += `floor : ${pickupAddress_floor.before}, `;
        if (pickupAddress_isWorkAddress?.before)
          formattedAddress += `work address : ${
            pickupAddress_isWorkAddress.before ? 'Yes' : 'No'
          }`;
      }
    }

    if (formattedAddress !== '') {
      return formattedAddress;
    }
    return 'N/A';
  };

  _formatAfterAddress = (customerAddress) => {
    let formattedAddress = '';
    if (customerAddress.firstLine)
      formattedAddress += `first line:${customerAddress.firstLine}, `;
    if (customerAddress.secondLine)
      formattedAddress += `second line:${customerAddress.secondLine}, `;
    if (customerAddress.district?.name)
      formattedAddress += `dist: ${customerAddress.district.name}, `;
    if (customerAddress.zone?.name)
      formattedAddress += `zone: ${customerAddress.zone.name}, `;
    if (customerAddress.city?.name)
      formattedAddress += `city : ${customerAddress.city.name}`;

    if (formattedAddress !== '') {
      return formattedAddress;
    }
    return 'N/A';
  };

  _formatRateLog = (actionsList) => {
    let logString = `Rate is added with value ${
      actionsList.rate?.after || 'N/A'
    }`;
    if (actionsList.rateReason?.after) {
      logString += `, consignee feedback: "${actionsList.rateReason?.after}"`;
    }
    return logString;
  };
  //for handling whatsapp logs addresses
  _formatAddres = (address) => {
    let formattedAddress = '';
    const splitAddress = address.split(',');
    splitAddress.forEach((address) => {
      formattedAddress += address.length > 1 ? address.split(':')[1] + ' ' : '';
    });
    return formattedAddress;
  };
}

export default new DeliveryStateFormatter();
